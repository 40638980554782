import React from "react";
import WhatshotIcon from "@mui/icons-material/Whatshot";

export const Info = () => {
  return (
    <div className="bg-white bg-opacity-100 flex items-center justify-center pb-10">
      <div className="max-w-5xl mx-auto bg-white px-2">
        <h1 className="text-2xl font-black text-black my-4 flex gap-1 items-center">
          <WhatshotIcon sx={{ color: "#ff477b" }} />
          What is pass the lightr?
        </h1>
        <p className="text-md text-black">
          Pass the Lightr is a project created by Daan Kuiper and Gonzalo
          Alonso. The both of us have always found it fascinating how you lose
          lighters and how you find a new one in your pocket after a night out
          for instance. This gave us the idea of tracking them and seeing how
          many kilometres a lighter might travel and what it comes along on its
          journey!
        </p>
        <h1 className="text-2xl font-black text-black my-4 flex gap-1 items-center">
          <WhatshotIcon sx={{ color: "#ff477b" }} />
          How does it work?
        </h1>
        <p className="text-md text-black">
          Scan your lighter, enjoy it for a while, and pass it on to the next
          person! Help lighting up the world, and remember the code so you can
          track the story of the lighter!
        </p>
      </div>
    </div>
  );
};
