import { Hero } from "../components/Hero";
import { HotItemsCarousel } from "../components/HotItemsCarousel";
import { Info } from "../components/Info";
import { Stats } from "../components/Stats";

export const Home = () => {
  // const { setLighter } = useContext(LighterContext);
  // useEffect(() => {
  //   const get = async () => {
  //     const l = await getOneLighter("1AKV5");
  //     setLighter(l!);
  //   };
  //   get();
  // }, []);
  return (
    <>
      <Hero />
      <Stats />
      <Info />
      {/* <HotItemsCarousel /> */}
      {/* <button className="std-btn" onClick={resetTestLighter}>reset test lighter</button> */}
      {/* <div className="w-full max-w-4xl py-10 mx-auto">
        <LatestStory />
      </div> */}
    </>
  );
};
